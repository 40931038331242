import './index.css'
import 'typeface-roboto'

import firebase from 'firebase/app'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import loadable from '@loadable/component'
import lightBlue from '@material-ui/core/colors/lightBlue'
import teal from '@material-ui/core/colors/teal'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import firebaseConfig from './firebase.config'
import * as serviceWorker from './serviceWorker'

const AppContainer = loadable(() => import('./ui/containers/AppContainer'))

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: lightBlue,
    secondary: teal,
  },
})

firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
    <SnackbarProvider maxSnack={5}>
      <CssBaseline />
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
