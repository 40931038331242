const firebaseConfig = {
  apiKey: 'AIzaSyCQzthKOip63M2_wiHs6WUqAASvV73EZ4o',
  authDomain: 'pwtycoon-5a2a3.firebaseapp.com',
  databaseURL: 'https://pwtycoon-5a2a3.firebaseio.com',
  projectId: 'pwtycoon-5a2a3',
  storageBucket: 'pwtycoon-5a2a3.appspot.com',
  messagingSenderId: '609511003963',
  appId: '1:609511003963:web:febe9f714ce7946e4a891e',
  measurementId: 'G-MSFYT379F6',
}

export default firebaseConfig
